import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/kontakt'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'

import EmailIcon from '../assets/emailIcon'
import PhoneIcon from '../assets/phoneIcon'
import LocationIcon from '../assets/locationIcon'
import FaxIcon from '../assets/faxIcon'
import HotlineIcon from '../assets/hotlineIcon'

import MapOffices from '../sections/kontakt/MapOffices'
import ContactInfo from '../sections/kontakt/ContactInfo'
import { useFormContext } from '../context/form.context'
import FormNewsletter from '../components/FormNewsletter'
// import FormNewsletter from '../components/FormNewsletter'

const Kontakt = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()
  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/kontakt.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  const info = [
    {
      title: 'aleja Grunwaldzka 472C, 80-309 Gdańsk, Poland',
      link: 'https://www.google.com/maps/dir//aleja+Grunwaldzka+472C,+80-309+Gda%C5%84sk/@54.4035938,18.4888313,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x46fd75698ec99701:0xf61eae87044c1b!2m2!1d18.5712313!2d54.4036218?entry=ttu&g_ep=EgoyMDI1MDIxMi4wIKXMDSoASAFQAw%3D%3D',
      icon: () => <LocationIcon />,
      span: true,
    },
    {
      title: 'bok@omida.pl',
      link: 'mailto:bok@omida.pl',
      icon: () => <EmailIcon />,
    },
    {
      title: 'Biuro Obsługi Klienta: <br/>+48 58 741 88 14',
      link: 'tel:+48587418814',
      icon: () => <PhoneIcon />,
    },
    {
      title: 'Płatności dla przewoźników: <br/>+48 58 333 10 08',
      link: 'tel:+48583331008',
      icon: () => < HotlineIcon />,
    },
    {
      title: `${lang === 'en' ? 'TAX: PL5851466250 ' : 'NIP: 585 146 62 50 '}`,
    },
    {
      title: `${lang === 'en' ? 'NCR: 0000459017' : 'KRS: 0000459017'}`,
    },
    {
      title: 'REGON: 221867080',
    },
  ]

  const button = {
    text: {
      pl: 'Szukaj oddziału',
      en: 'Search for office',
    },
    link: '/kontakt/#oddzialy',
  }

  const buttonSecond = {
    text: {
      pl: 'Wyślij wiadomość',
      en: 'Send message',
    },
    action: () => setFormOpen(true),
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/kontakt.jpg'}
        url={seo.url}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
      />
      <Main data={main} h={1} />
      {/* <FormNewsletter /> */}
      <ContactInfo data={info} />
      <MapOffices />
      <FormNewsletter />
    </Layout>
  )
}

export default Kontakt
