import React from 'react'

const LocationOmida = (props) => (
  <svg 
      css={{
      position: 'absolute',
      top: '0%',
      left: '50%',
      width: 64,
      height: 64,
      transform: 'translate(-50%, -100%)',
    }}
  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="43px" height="60px" viewBox="0 0 43 60" version="1.1" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group">
            <g id="Page-1_00000106856759493670496740000009563377203935722395_" fill="#FFFFFF" fillRule="nonzero">
                <g id="Group_00000116922084373169175730000017644055158027071376_">
                    <path d="M20.3,58.3 C20.8,59.3 21.8,59.3 22.3,58.3 L40.1,27.9 C41.6,25.4 42.6,22.3 42.6,18.8 C42.6,8.6 33,0 21.3,0 C9.6,0 6.66133815e-16,8.6 6.66133815e-16,19.2 C6.66133815e-16,22.2 1,25.3 2.5,28.3 L20.3,58.3 L20.3,58.3 Z" id="Path_00000168812258946134823460000001319003552550714761_"/>
                </g>
            </g>
            <path d="M27.9,7.6 L14.7,7.6 C11.1,7.6 8.1,10.6 8.1,14.2 L8.1,27.9 C8.1,31.5 11.1,34.5 14.7,34.5 L28.4,34.5 C32,34.5 35,31.5 35,27.9 L35,14.2 C34.5,10.6 31.4,7.6 27.9,7.6 Z M21.3,32.9 L9.1,11.6 L19.8,11.6 L21.3,14.6 L22.8,11.6 L33.5,11.6 L21.3,32.9 Z" id="Shape" fill="#AFCA36"/>
            <polygon id="Path" fill="#AFCA36" points="28.9 14.7 21.3 27.3 13.7 14.7 18.8 14.7 20.3 17.2 18.3 17.2 21.3 22.3 24.3 17.2 22.3 17.2 23.8 14.7"/>
        </g>
    </g>
</svg>
)

export default LocationOmida
